// Vendor Files
import React from "react";
import { useParams } from "react-router";

// Custom Components

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Mukpath extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div>
                <iframe
                    src="https://onepranav.github.io/Bravo/"
                    width="50%"
                    height="50%"
                    style={{ position: "fixed", top: "0", left: "0", right: "0", bottom: "0", border: "none", margin: "0", padding: "0", overflow: "hidden", "z-index": "999999" }}
                />
                <iframe
                    src="https://onepranav.github.io/Bravo/"
                    width="50%"
                    height="50%"
                    style={{ position: "fixed", top: "0", right: "0", bottom: "0", border: "none", margin: "0", padding: "0", overflow: "hidden", "z-index": "999999" }}
                />
                <iframe
                    src="https://onepranav.github.io/Bravo/"
                    width="50%"
                    height="50%"
                    style={{ position: "fixed", left: "0", right: "0", bottom: "0", border: "none", margin: "0", padding: "0", overflow: "hidden", "z-index": "999999" }}
                />
                <iframe
                    src="https://onepranav.github.io/Bravo/"
                    width="50%"
                    height="50%"
                    style={{ position: "fixed", right: "0", bottom: "0", border: "none", margin: "0", padding: "0", overflow: "hidden", "z-index": "999999" }}
                />
            </div>
        );
    }
}

export default withParams(Mukpath);
