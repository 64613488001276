// Vendor Files
import React from "react";
import { Form, Modal, Alert, Button } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from "react-router";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class RSVPParasabhaPledge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rsvpName: "",
            rsvpPhone: "",
            rsvpZonename: "",
            rsvpMonths: [],
            rsvpOccasion: "",
            rsvpComments: "",
            zoneList: ["Addison", "Aurora", "Bartlett - East", "Bartlett - West", "Buffalo Grove", "Carol Stream", "Chicago - Devon", "Chicago - Downtown", "Chicago - Lawrence", "Chicago - Midway", "Chicago - Ohare", "Cornerstone", "Des Plaines - Skokie", "Elgin", "Elk Grove Village", "Glendale Heights", "Gurnee", "Hanover Park", "Hoffman Estates", "Joliet", "Naperville", "Palatine", "Rockford", "Schaumburg", "South Elgin", "St. Charles", "Streamwood", "West Chicago", "Westmont"],
            rsvpShowFormWarning: false,
            rsvpWarningText: "",
            rsvpDisableRegisterBtn: false,
            rsvpShowSuccessMsg: false,
            rsvpSuccessText: "",
        };
    }

    componentDidMount() {
        if (this.props.formData.displayName.indexOf("Hindi") >= 0) {
            this.setState({ rsvpUniversity: "" });
        }
    }

    handleClose() {
        this.props.hideForm();
    }

    handleRSVPRegister() {
        // Check fields are filled
        let unfilledFields = this.state.rsvpName === "" || this.state.rsvpPhone === "" || this.state.rsvpZonename === "" || this.state.rsvpMonths.length == 0;
        if (unfilledFields === true) {
            this.setState({ rsvpWarningText: "Please fill out the required fields.", rsvpShowFormWarning: true });
            return;
        }

        // let url = "http://localhost:5004/api/parasabha-pledge" // Dev testing
        let url = "https://mw-chicago-mandir-backend.web.app/api/parasabha-pledge" // Production
        let body = {
            name: this.state.rsvpName,
            phone: this.state.rsvpPhone,
            zonename: this.state.rsvpZonename,
            months: JSON.stringify(this.state.rsvpMonths).replace(/"/g, '\\"'),
            occasion: this.state.rsvpOccasion,
            comments: this.state.rsvpComments
        }
        this.setState({ rsvpDisableRegisterBtn: true });
        axios.post(url, body).then((res) => {
            if (res.data.id) {
                this.setState({ rsvpDisableRegisterBtn: false, rsvpShowSuccessMsg: true, rsvpShowFormWarning: false });
                this.handleResetFormFields();
            }
            else {
                this.setState({ rsvpDisableRegisterBtn: false });
                this.setState({ rsvpWarningText: "An error occured while sending your information. Please try again.", rsvpShowFormWarning: true });
            }
        });
    }

    handleMonthSelection(monthValue, checked) {
        this.setState(prevState => {
            const { rsvpMonths } = prevState;
            if (checked) {
                // Append monthValue if not already present
                return { rsvpMonths: [...rsvpMonths, monthValue] };
            } else {
                // Remove monthValue if present
                return { rsvpMonths: rsvpMonths.filter(value => value !== monthValue) };
            }
        });
    }

    handleResetFormFields() {
        this.setState({
            rsvpName: "",
            rsvpPhone: "",
            rsvpZonename: "",
            rsvpMonths: "",
            rsvpOccasion: "",
            rsvpComments: "",
        });
    }

    render() {
        return (
            <Modal show={true} onHide={() => this.handleClose()} size="lg" className="modalCont" scrollable={true}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="modalHeader">
                        <h2>{this.props.formData.displayName}</h2>
                        <p>Thank you for your contribution. We pray to Bhagwan Swaminarayan and Guruhari Mahant Swami Maharaj to bless you & your family with peace, prosperity & happiness.</p>
                    </div>

                    {this.state.rsvpShowFormWarning ? <Alert variant={"danger"}>{this.state.rsvpWarningText}</Alert> : ""}
                    {this.state.rsvpShowSuccessMsg ? <Alert variant={"success"}>Thank you for your participation and contribution.</Alert> : ""}
                    {!this.state.rsvpShowSuccessMsg &&
                        <Form autoComplete="disabled">
                            <Form.Group className="mb-3">
                                <Form.Label>Name:<sup>*</sup></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your full name"
                                    name="name"
                                    value={this.state.rsvpName}
                                    onChange={(event) => this.setState({ rsvpName: event.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Cell Phone (no dashes):<sup>*</sup></Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter your phone number"
                                    name="phone"
                                    value={this.state.rsvpPhone}
                                    onChange={(event) => this.setState({ rsvpPhone: event.target.value.substring(0,10) })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Select your Zone:<sup>*</sup></Form.Label>
                                <Form.Control as="select" value={this.state.rsvpZonename} onChange={(event) => this.setState({ rsvpZonename: event.target.value })}> 
                                    <option className="hideOption"></option>
                                    <option disabled value=""></option>
                                    {this.state.zoneList.map(zone => {
                                        return (
                                            <option key={zone}>{zone}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Select month(s) of your choice for Para sabha sponsorship ($151 per para sabha/month):<sup>*</sup></Form.Label>
                                {/* <Form.Check type="checkbox" id="january-checkbox" label="January" onChange={(event) => { this.handleMonthSelection('January', event.target.checked) }} className="pl-0" /> */}
                                <Form.Check type="checkbox" id="february-checkbox" label="February" onChange={(event) => { this.handleMonthSelection('February', event.target.checked) }} className="pl-0" />
                                <Form.Check type="checkbox" id="march-checkbox" label="March" onChange={(event) => { this.handleMonthSelection('March', event.target.checked) }} className="pl-0" />
                                <Form.Check type="checkbox" id="april-checkbox" label="April" onChange={(event) => { this.handleMonthSelection('April', event.target.checked) }} className="pl-0" />
                                <Form.Check type="checkbox" id="may-checkbox" label="May" onChange={(event) => { this.handleMonthSelection('May', event.target.checked) }} className="pl-0" />
                                <Form.Check type="checkbox" id="june-checkbox" label="June" onChange={(event) => { this.handleMonthSelection('June', event.target.checked) }} className="pl-0" />
                                <Form.Check type="checkbox" id="july-checkbox" label="July" onChange={(event) => { this.handleMonthSelection('July', event.target.checked) }} className="pl-0" />
                                <Form.Check type="checkbox" id="august-checkbox" label="August" onChange={(event) => { this.handleMonthSelection('August', event.target.checked) }} className="pl-0" />
                                <Form.Check type="checkbox" id="september-checkbox" label="September" onChange={(event) => { this.handleMonthSelection('September', event.target.checked) }} className="pl-0" />
                                <Form.Check type="checkbox" id="october-checkbox" label="October" onChange={(event) => { this.handleMonthSelection('October', event.target.checked) }} className="pl-0" />
                                <Form.Check type="checkbox" id="november-checkbox" label="November" onChange={(event) => { this.handleMonthSelection('November', event.target.checked) }} className="pl-0" />
                                <Form.Check type="checkbox" id="december-checkbox" label="December" onChange={(event) => { this.handleMonthSelection('December', event.target.checked) }} className="pl-0" />
                                <Form.Check type="checkbox" id="later-checkbox" label="Will inform later" onChange={(event) => { this.handleMonthSelection('Will inform later', event.target.checked) }} className="pl-0" />

                            </Form.Group>        

                            <Form.Group className="mb-3">
                                <Form.Label>Occasion (Birthday/Wedding anniversary, Punya Tithi, etc):</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your occasion"
                                    name="occasion"
                                    value={this.state.rsvpOccasion}
                                    onChange={(event) => this.setState({ rsvpOccasion: event.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Please fill in any comments or message about your sponsorship:</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter any comments"
                                    name="comments"
                                    value={this.state.rsvpComments}
                                    onChange={(event) => this.setState({ rsvpComments: event.target.value })}
                                />
                            </Form.Group>
                        </Form>
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" className="mr-auto" onClick={() => this.handleClose()}>
                        Close
                    </Button>
                    {!this.state.rsvpShowSuccessMsg &&
                        <div>
                            <Button variant="primary" onClick={() => this.handleRSVPRegister()} disabled={this.state.rsvpDisableRegisterBtn}>
                                Pledge Now
                            </Button>
                        </div>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withParams(RSVPParasabhaPledge);