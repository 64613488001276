export const eventsList = {
    'student-seminar': {
        'name': 'student-seminar',
        'displayName': 'Think Different. Be Different. Succeed Different.',
        'eventDate': 'Thursday, August 17, 2023',
        'eventTime': '7:00 PM - 9:00 PM',
        'active': false
    },
    'campus-diwali': {
        'name': 'campus-diwali',
        'displayName': 'Diwali Inspires',
        'eventDate': 'Thursday, November 16, 2023',
        'eventTime': '7:00 PM - 9:00 PM',
        'active': false
    },
    'ssm24': {
        'name': 'ssm24',
        'displayName': 'Satsang Suhrad Milan',
        'eventDate': 'Friday, January 19, 2024 | Friday, January 26, 2024',
        'eventTime': '5:30 PM - 9:00 PM',
        'active': false
    },
    'student-sabha': {
        'name': 'student-sabha-mar-25',
        'displayName': 'Student Sabha',
        'eventDate': 'Thursday, March 20th, 2024',
        'eventTime': '7:00 PM - 9:00 PM',
        'active': true
    },
    'uic-student-sabha': {
        'name': 'uic-student-sabha-sep',
        'displayName': 'Student Sabha',
        'eventDate': 'Thursday, September 12th, 2024',
        'eventTime': '7:00 PM - 9:00 PM',
        'active': true
    },
    'hindi-sabha': {
        'name': 'hindi-sabha-sep',
        'displayName': 'Hindi Sabha',
        'eventDate': 'Sunday, September 8th, 2024',
        'eventTime': '4:00 PM - 5:30 PM',
        'active': true
    },

    // Samarpan Events
    'samarpan-sabha': {
        'name': 'samarpan-sabha-sep-12',
        'displayName': 'Samarpan Sabha',
        'eventDate': 'Thursday, September 12th, 2024',
        'eventTime': '7:00 PM - 8:30 PM',
        'notEmail': true,
        'active': true
    },

    // Vadil Milan
    'vadil-milan': {
        'name': 'vadil-milan-feb-25-sabha1',
        'displayName': 'Vadil Milan',
        'eventDate': 'Monday, February 10th, 2025',
        'eventTime': '5:00 PM - 7:00 PM',
        'notEmail': true,
        'active': true
    },
};