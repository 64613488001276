export const eventsList = [
    {
        'id': 'ssm',
        'name': "Satsang Suhrad Milan",
        'displayName': "Satsang Suhrad Milan",
        'description': "Annual Karyakar Appreciation Sabha (Only for invited guests)",
        'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'eventDays': ['Friday, January 19th - 5:30 PM'],
        'needRsvp': {
            'text': 'Register Now'
        },
        'formData': {
            'formName': 'rsvpSSM',
            'eventId': 'ssm',
            'eventName': 'Satsang Suhrad Milan - 2024',
            'displayName': 'Satsang Suhrad Milan',
            'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
            'time': '5:30 PM - 9:00 PM'
        },
        'hideList': true,
        'active': false
    },

    // Student Sabha - IIT
    {
        'id': 'student-sabha',
        'name': "Student Sabha - March",
        'displayName': "Student Sabha",
        'description': "Fill out form below to register for the event. We look forward to seeing you.",
        'location': 'Kings Room, Pershing Magnet School, 3200 S Calumet Ave, Chicago, IL 60616',
        'needRsvp': {
            'text': 'Register Now'
        },
        'formData': {
            'formName': 'rsvpStudentSabha',
            'eventId': 'student-sabha-mar-25',
            'eventName': 'Student Sabha',
            'displayName': 'Student Sabha',
            'description': "Fill out form below to register for the event. We look forward to seeing you.",
            'location': 'Kings Room, Pershing Magnet School, 3200 S Calumet Ave, Chicago, IL 60616',
            'date': 'Thursday, March 20th, 2025',
            'time': '7:00 PM - 9:00 PM',
            'eventDays': ['Thursday March 20th - 7:00 PM']
        },
        'hideList': true,
        'active': true
    },

    // Student Sabha - UIC
    {
        'id': 'uic-student-sabha',
        'name': "Student Sabha - September",
        'displayName': "Student Sabha",
        'description': "Fill out form below to register for the event. We look forward to seeing you.",
        'location': 'Whitney M. Young Magnet High School, 211 S Laflin St, Chicago, IL 60607',
        'needRsvp': {
            'text': 'Register Now'
        },
        'formData': {
            'formName': 'rsvpStudentSabha',
            'eventId': 'uic-student-sabha-sep',
            'eventName': 'Student Sabha',
            'displayName': 'Student Sabha',
            'description': "Fill out form below to register for the event. We look forward to seeing you.",
            'location': 'Whitney M. Young Magnet High School, 211 S Laflin St, Chicago, IL 60607',
            'date': 'Thursday, September 12th, 2024',
            'time': '7:00 PM - 9:00 PM',
            'eventDays': ['Thursday September 12th - 7:00 PM']
        },
        'hideList': true,
        'active': true
    },

    {
        'id': 'diwali-samaiyo',
        'name': "Diwali Samaiyo",
        'displayName': "Diwali Samaiyo",
        'description': "Celebrate the first Gujarati satsang sabha (weekly spiritual assembly) of the Hindu New Year with spiritual discourses, inspiring videos, and kirtan-bhakti.",
        'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'date': 'Sat, November 9th | Sun, November 10th',
        'time': '4:00 PM - 6:00 PM',
        'eventStartDateTime': '2024-11-10T16:00',
        'eventEndDateTime': '2024-11-10T19:00',
        'imageURL': 'https://chicagomandir.org/static/media/diwali_samaiyo.f1a915ae.jpg',
        'image': 'diwali_samaiyo.jpg',
        'active': true
    },

    // Hindi Sabha
    {
        'id': 'hindi-sabha',
        'name': "Hindi Sabha - February",
        'displayName': "Hindi Satsang Sabha",
        'description': "Join us for our monthly Hindi seminar series. We will dive into the values and stories associated with Hinduism.",
        'location': 'BAPS Shri Swaminarayan Mandir, MPR1 | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'date': 'Sunday, February 9th',
        'time': '4:00 PM - 6:00 PM',
        'eventStartDateTime': '2025-02-09T16:00',
        'eventEndDateTime': '2025-02-09T18:00',
        'image': 'HindiSabha.jpg',
        'imageURL': 'https://chicagomandir.org/static/media/HindiSabha.aff0c072.jpg',
        'needRsvp': {
            'text': 'Register Now'
        },
        'formData': {
            'formName': 'rsvpStudentSabha',
            'eventId': 'hindi-sabha-february-25',
            'eventName': 'Hindi Sabha',
            'displayName': 'Hindi Satsang Sabha',
            'description': "Fill out form below to register for the event. We look forward to seeing you.",
            'location': 'BAPS Shri Swaminarayan Mandir, MPR1 | 1851 S IL Route 59, Bartlett, Illinois 60103',
            'date': 'Sunday, February 9th, 2025',
            'time': '4:00 PM - 6:00 PM',
            'eventDays': ['Sunday February 9th - 4:00 PM']
        },
        'active': true
    },

    // THW Event
    {
        'id': 'thw',
        'name': "Timeless Hindu Wisdom",
        'displayName': "Timeless Hindu Wisdom - November",
        'description': "There's an old saying, 'Sticks and stones may break my bones, but words will never hurt me.' However, words are much more powerful than one can imagine! Join us as we learn from Bhagavad Gita verse 2/53 and how words can help us grow or fall from our career, relationships and even the spiritual path.",
        'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
        'date': 'Sunday, November 17th',
        'time': '11:30 PM - 1:30 PM',
        'eventStartDateTime': '2024-11-17T11:30',
        'eventEndDateTime': '2024-11-17T13:30',
        'image': 'thw_logo.PNG',
        'imageURL': 'https://chicagomandir.org/static/media/thw_logo.38714816.PNG',
        'actionLink': {
            'text': 'RSVP for this session',
            'newTab': true,
            'url': 'https://t.me/+Rk1iR9iga8Dxj-9p'
        },
        'active': true
    },

    // Parasabha Pledge Event
    {
        'id': 'parasabha-pledge',
        'name': "Parasabha Sponsorhip",
        'displayName': "Parasabha Sponsorhip",
        'eventStartDateTime': '2025-01-01T11:30',
        'eventEndDateTime': '2024-12-31T13:30',
        'needRsvp': {
            'text': 'Pledge Now'
        },
        'formData': {
            'formName': 'rsvpParasabhaPledge',
            'eventId': 'parasabha-pledge',
            'eventName': 'Parasabha Sponsorhip',
            'displayName': 'Parasabha Sponsorhip',
        },
        'hideList': true,
        'active': true
    },

    
    // Sample Event
    // {
    //     'id': 'psmjj',
    //     'name': "Pramukh Swami Maharaj Birthday - Mahila Celebration 2",
    //     'displayName': "Pramukh Swami Maharaj Birthday - Mahila Celebration 2",
    //     'description': "Pramukh Swami Maharaj inspired thousands through his deep spirituality, humility and dedication to service. Pramukh Swami Maharaj’s birthday will be celebrated in a special event by women for women on December 17, 2023.",
    //     'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
    //     'date': 'Sunday, December 17th',
    //     'time': '4:00 PM - 6:00 PM',
    //     'eventStartDateTime': '2024-12-18T16:00',
    //     'eventEndDateTime': '2024-12-18T18:00',
    //     'image': 'PSMMahilaSamaiyo.jpeg',
    //     'imageURL': 'https://www.baps.org/Data/Sites/1/Media/LocationImages/upcomingevents/WebImages/pramukh_swami_maharaj_007.jpg',
    //     'eventDays': ['Sunday, December 17th - 12:00 PM'],
    //     'needRsvp': {
    //         'text': 'Register Now'
    //     },
    //     'formData': {
    //         'formName': 'rsvpEvent',
    //         'eventId': 'psmjj',
    //         'eventName': 'Pramukh Swami Maharaj Birthday - Mandir',
    //         'displayName': 'Pramukh Swami Maharaj Birthday',
    //         'location': 'BAPS Shri Swaminarayan Mandir | 1851 S IL Route 59, Bartlett, Illinois 60103',
    //         'time': '4:00 PM - 6:00 PM',
    //         'description': 'Join us for a cordial gathering of the Hindu community in a unique celebration of unity and harmony. Let us connect to exchange ideas and inspire collaboration. Let us grow together.',
    //         'eventDays': ['Sunday January 7th - 4:00 PM', 'Sunday January 8th - 4:00 PM'],
    //     },
    //     'active': false
    // },
];